.donar-bg{
    background: url("../assets/bg4.4.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh !important;
}
.expandir{
    transition:  all 1s ease !important;
}
.expandir:hover {
    transform: scale(1.3) !important;

}