.main-banner-image{
    background: url("../assets/b2.1.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh !important;    
}
.logo{
    width: 30% !important;
}